//TICKET 124573 - 3.5. Create Account managers
import { ACCOUNT_MANAGER_SUBMITTED, ACCOUNT_MANAGER_REMOVE, accountManagerRemovalResultRecieved } from './actions';
import { switchMap, mapTo, mergeMap, map } from 'rxjs/operators';
import { saveAccountManagerQuery, removeAccountManagerQuery } from './queries';
import { navigateTo } from 'behavior/events';
import { routesBuilder } from 'routes';
import { merge, of } from 'rxjs';
import { catchApiErrorWithToast, retryWithToast } from 'behavior/errorHandling';
import { unlockForm, FormLockKeys } from 'behavior/pages';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { ofType } from 'redux-observable';

export default function (action$, _state$, dependencies) {
  const { api, logger } = dependencies;
  const forManageAccounts = routesBuilder.forManageAccounts();

  const savingAccountManager$ = action$.pipe(
    ofType(ACCOUNT_MANAGER_SUBMITTED),
    switchMap(action => api.graphApi(saveAccountManagerQuery, { acc: action.payload }, { retries: 0 }).pipe(
      mapTo(navigateTo(forManageAccounts)),
      catchApiErrorWithToast(['INVALID_INPUT'], of(unlockForm(FormLockKeys.AccountManager))),
      retryWithToast(action$, logger, _ => of(unlockForm(FormLockKeys.AccountManager))),
    )),
  );


  const removingAccountManager$ = action$.pipe(
    ofType(ACCOUNT_MANAGER_REMOVE),
    mergeMap(action => api.graphApi(removeAccountManagerQuery, { id: action.payload }, { retries: 0 }).pipe(
      map(() => accountManagerRemovalResultRecieved({ accountManagerId: action.payload })),
      mapTo(navigateTo(forManageAccounts)),
      handleError(ERROR_PRIORITIES.HIGH, 'profile'),
    )),
  );

  return merge(savingAccountManager$, removingAccountManager$);
}
