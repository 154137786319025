//TICKET 124573 - 3.5. Create Account managers
import { newAccountManagerPageQuery, accountManagerPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RouteName } from 'routes';
import { PageComponentNames } from '../componentNames';

export default ({ routeName, params }, _state$, { api }) => {
  const isEdit = routeName === RouteName.EditAccountManager;

  const id = params?.id;

  if (isEdit && !id)
    return of(null);

  const request = isEdit
    ? api.graphApi(accountManagerPageQuery, { id })
    : api.graphApi(newAccountManagerPageQuery);

  return request.pipe(
    map(({ pages, profile: { accountManagers } }) => {
      const page = pages.accountManager;
      if (!page || !accountManagers)
        return null;

      page.component = PageComponentNames.AccountManager;

      page.accountManager = accountManagers.search && accountManagers.search.list[0];

      if (isEdit && !page.accountManager)
        return null;

      return { page };
    }),
    initSystemPageContent(),
  );
};
