//TICKET 124573 - 3.5. Create Account managers
export const ACCOUNT_MANAGER_SUBMITTED = 'ACCOUNT_MANAGER_SUBMITTED';
export const submitAccountManager = account => ({
  type: ACCOUNT_MANAGER_SUBMITTED,
  payload: account,
});

export const ACCOUNT_MANAGER_REMOVE = 'ACCOUNT_MANAGER_REMOVE';
export const removeAccountManager = id => ({
  type: ACCOUNT_MANAGER_REMOVE,
  payload: id,
});

export const ACCOUNT_MANAGER_REMOVAL_RESULT_RECEIVED = 'ACCOUNT_MANAGER_REMOVAL_RESULT_RECEIVED';
export const accountManagerRemovalResultRecieved = result => ({
  type: ACCOUNT_MANAGER_REMOVAL_RESULT_RECEIVED,
  payload: result,
});