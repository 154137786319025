//TICKET 124425 3.1 Branch visibility at the sub-account creation
import {
  SUB_ACCOUNT_BILLTOCUSTOMERS_RECEIVED,
  BillToCustomerAction,
} from './actions';
import type { BillToCustomerListType } from './types';

export default (state = {}, action: BillToCustomerAction) => {
  if (action.type === SUB_ACCOUNT_BILLTOCUSTOMERS_RECEIVED)
    return onReceivedBillToCustomers(state, action);

  return state;
};

function onReceivedBillToCustomers(state: any, { payload }: { payload: BillToCustomerListType }) {
  return {
    ...state,
    billToCustomerList: payload.list,
  };
}