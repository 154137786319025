//TICKET 124573 - 3.5. Create Account managers
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Paging from 'components/primitives/paging';
import { useUrlBuilder } from '../hooks';
import { navigateTo } from 'behavior/events';
import { MaxRecordsPerPage } from 'behavior/pages/manageAccounts'

const LinksPaging = ({ pageIndex, totalCount, navigateTo, hasPendingChanges }) => {
  const buildRoute = useUrlBuilder(pagingMiddleware);
  const buildUrlForPage = (page) => buildRoute({
    accountManagerPage: page + 1,
    count: page.size,
  });

  const onSelect = page => {
    const { to, url } = buildUrlForPage(page);
    navigateTo(to, url, { omitScroll: true });

    if (hasPendingChanges)
      return false;
  };

  const getUrl = page => {
    const { url } = buildUrlForPage(page);
    return url;
  };

  return (
    <Paging
      pageIndex={pageIndex}
      pageSize={MaxRecordsPerPage}
      totalCount={totalCount}
      onSelect={onSelect}
      scrollToId="accountManagersPage"
      getUrl={getUrl}
    />
  );
};

LinksPaging.propTypes = {
  pageIndex: PropTypes.number,
  totalCount: PropTypes.number,
  navigateTo: PropTypes.func.isRequired,
  hasPendingChanges: PropTypes.bool,
};

export default connect(({
  routing: { routeData: { params } },
}) => ({
  pageIndex: params.accountManagerPage ? params.accountManagerPage - 1 : 0,
}), { navigateTo })(LinksPaging);

function pagingMiddleware(query, params) {
  if (params.accountManagerPage > 0)
    query.accountManagerPage = params.accountManagerPage;
}