//TICKET 124573 - 3.5. Create Account managers
import { loadAccountsPageQuery, getLoadSubAccountsPreviewPageQuery } from './queries';
import { initSystemPageContent } from '../system';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { generateKey } from 'utils/helpers';
import { MaxRecordsPerPage } from './constants';

export default ({ params }, _state$, { api }) => {

  //Paging for Manage Accounts page
  const subAccountPaging = createPagingForSubAccounts(params);
  const accountManagerPaging = createPagingForAccountManagers(params);

  return api.graphApi(loadAccountsPageQuery, { subAccountPage: subAccountPaging, accountManagerPage: accountManagerPaging }).pipe(
    map(({ pages, profile: { subAccounts, accountManagers } }) => {

      const page = pages.manageAccounts;
      if (!page || (!subAccounts && !accountManagers))
        return null;

      page.component = PageComponentNames.ManageAccounts;
      page.subAccounts = subAccounts.search;
      page.totalSubAccounts = subAccounts.totalSubAccounts;
      page.accountManagers = accountManagers.search;
      page.totalAccountManagers = accountManagers.totalAccountManagers
      page.options = subAccounts.options;
      return { page };
    }),
    initSystemPageContent(),
  );
};

const createPagingForSubAccounts = (params) => {
  let subAccountPaging = {};
  if (params.subAccountPage)
    subAccountPaging = { index: (params.subAccountPage) - 1, size: MaxRecordsPerPage };
  else
    subAccountPaging = { index: 0, size: MaxRecordsPerPage };
  return subAccountPaging;
}

const createPagingForAccountManagers = (params) => {
  let accountManagerPaging = {};
  if (params.accountManagerPage)
    accountManagerPaging = { index: (params.accountManagerPage) - 1, size: MaxRecordsPerPage };
  else
    accountManagerPaging = { index: 0, size: MaxRecordsPerPage };
  return accountManagerPaging;
}