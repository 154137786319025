import styles from './AccountMenu.module.scss';
import { memo, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { VerticalSliding } from 'components/primitives/transitions';
import AccountMenuItems from './accountMenuItems';

const slidingDuration = 200;

export const accordionId = 'mobileLoginInfo';

const MobileAccountMenu = ({ expanded, setExpanded }) => {
  const { isLoading, isAuthenticated, isImpersonating, shopAccountType, role } = useSelector(s => ({
    isLoading: s.isLoading,
    isAuthenticated: s.user.isAuthenticated,
    isImpersonating: s.user.isImpersonating,
    shopAccountType: s.user.shopAccountType,
    role: s.user.role,
  }), shallowEqual);

  useEffect(() => {
    if (!isLoading)
      return;

    setExpanded(false);
  }, [isLoading]);

  const handleInfoItemsClick = useCallback(({ target: { tagName } }) => {
    if (tagName !== 'A' && tagName !== 'BUTTON')
      return;

    setExpanded(false);
  }, [setExpanded]);

  return (
    <VerticalSliding
      id={accordionId}
      duration={slidingDuration}
      expanded={expanded}
      containerClass={`${styles.mobileContainer} ${expanded ? styles.expanded : ''}`}
      onClick={handleInfoItemsClick}
    >
      <AccountMenuItems
        isAuthenticated={isAuthenticated}
        isImpersonating={isImpersonating}
        shopAccountType={shopAccountType}
        logoutDelay={slidingDuration}
        role={role}
      />
    </VerticalSliding>
  );
};

MobileAccountMenu.propTypes = {
  expanded: PropTypes.bool,
  setExpanded: PropTypes.func.isRequired,
};

export default memo(MobileAccountMenu);
