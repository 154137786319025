//TICKET 124573 - 3.5. Create Account managers
import { Row, Col } from 'components/primitives/grid';
import { SimpleText } from 'components/sanaText';
import AccountManagerForm from './form/AccountManagerForm';

const Details = ({ accountManager }) => {
  return (
    <>
      <h1>
        <SimpleText textKey={(accountManager && accountManager.email) ? 'EditAccountManager' : 'CreateNewAccountManager'} />
      </h1>
      <Row>
        <Col md={9}>
          <AccountManagerForm account={accountManager} isEdit={(accountManager && accountManager.email) ? true : false} />
        </Col>
      </Row>
    </>
  );
};

export default Details;