//TICKET 124573 - 3.5. Create Account managers
import { ACCOUNT_MANAGER_REMOVAL_RESULT_RECEIVED } from './actions';

export default function (state, action) {
  if (action.type === ACCOUNT_MANAGER_REMOVAL_RESULT_RECEIVED)
    return onAccountManagersRemovalResultReceived(state, action);

  return state;
}

function onAccountManagersRemovalResultReceived(state, action) {
  const { accountManagerId } = action.payload || {};
  return {
    ...state,
    accountManagers: {
      list: state.accountManagers.list.filter(a => a.id !== accountManagerId),
    },
  };
}
