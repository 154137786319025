//TICKET 124573 - 3.5. Create Account managers
import loadable from '@loadable/component';

const ManageAccounts = loadable(() => import(/*webpackChunkName:"manage-acc"*/'./ManageAccounts'));


/* eslint-disable react/no-multi-comp */
export function manageAccountsRenderer(page) {
  return <ManageAccounts
    subAccountsList={page.subAccounts?.list}
    options={page.options}
    removalFailureInfo={page.removalFailureInfo}
    accountManagersList={page.accountManagers?.list}
    totalSubAccounts={page.totalSubAccounts}
    totalAccountManagers={page.totalAccountManagers}
  />;
}

