import { PageComponentNames } from 'behavior/pages';
import createRenderer from './renderer';
import profile from 'components/objects/profile';
import loginRenderer from 'components/objects/login';
import registrationRenderer from 'components/objects/registration';
import forgotPasswordRenderer from 'components/objects/forgotPassword';
import resetPasswordRenderer from 'components/objects/resetPassword';
import representRenderer from 'components/objects/representation';
import contactUsRenderer from 'components/objects/contactUs';
import notFoundRenderer from 'components/objects/notFound';
import subscribeRenderer from 'components/objects/newsletter/subscribe';
import unSubscribeRenderer from 'components/objects/newsletter/unsubscribe';
import lastViewedRenderer from 'components/objects/productList/lastViewed';
import {
  checkoutRenderer,
  orderSubmitRenderer,
  orderFailedRenderer,
  orderCancelledRenderer,
  paymentErrorRenderer,
} from 'components/objects/checkout';
import invoicePaymentRenderer from 'components/objects/invoicePayment';
import {
  orderPaymentRenderer,
  paymentSuccessfulRenderer,
  paymentCancelledRenderer,
  paymentFailedRenderer,
} from 'components/objects/orderPayment';
import representCompanyRenderer from 'components/objects/representationCompany';

export default {
  [PageComponentNames.Login]: createRenderer('Login_Title', loginRenderer),
  [PageComponentNames.Registration]: createRenderer('CreateAccount_MainHeader', registrationRenderer),
  [PageComponentNames.ForgotPassword]: createRenderer(null, forgotPasswordRenderer),
  [PageComponentNames.ResetPassword]: createRenderer(null, resetPasswordRenderer),
  ...profile,
  [PageComponentNames.Represent]: createRenderer('RepresentCustomer_Title', representRenderer),
  [PageComponentNames.ContactUs]: createRenderer('ContactUs_Title', contactUsRenderer),
  [PageComponentNames.NotFound]: createRenderer('PageNotFound_Title', notFoundRenderer),
  [PageComponentNames.Subscribe]: createRenderer('NewsletterSubscribe_Title', subscribeRenderer),
  [PageComponentNames.Unsubscribe]: createRenderer('NewsletterUnsubscribe_Title', unSubscribeRenderer),
  [PageComponentNames.LastViewedProducts]: createRenderer('LastViewedProducts_Header', lastViewedRenderer),
  [PageComponentNames.Checkout]: checkoutRenderer,
  [PageComponentNames.OrderSubmit]: orderSubmitRenderer,
  [PageComponentNames.OrderFailed]: orderFailedRenderer,
  [PageComponentNames.OrderCancelled]: orderCancelledRenderer,
  [PageComponentNames.PaymentError]: paymentErrorRenderer,
  [PageComponentNames.InvoicePayment]: invoicePaymentRenderer,
  [PageComponentNames.OrderPayment]: createRenderer('Payment_Header', orderPaymentRenderer),
  [PageComponentNames.PaymentSuccessful]: createRenderer('Payment_SuccessHeader', paymentSuccessfulRenderer),
  [PageComponentNames.PaymentCancelled]: createRenderer('Payment_CancelledHeader', paymentCancelledRenderer),
  [PageComponentNames.PaymentFailed]: createRenderer('Payment_PaymentFailureHeader', paymentFailedRenderer),

  // TICKET 124413 - 3.4.Represent linked companies
  [PageComponentNames.RepresentCompany]: createRenderer('RepresentCompany_Title', representCompanyRenderer),
};
