//TICKET 124573 - 3.5. Create Account managers
import { useCallback, useContext, useMemo } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import { stringifyQuery, parseQuery } from 'utils/url';
import { MaxRecordsPerPage } from 'behavior/pages/manageAccounts'

export const useUrlBuilder = (...middlewares) => {
  const { pathname, search, routeData, ...options } = useSelector(({
    routing: {
      routeData,
      location: { pathname, search },
    },
  }) => ({
    pathname,
    search,
    routeData,
  }), shallowEqual);

  return useCallback((params, routeOptions) => {
    params.count = MaxRecordsPerPage;
    params = { ...routeData.params, ...params };
    const { page, ...query } = parseQuery(search);

    for (const middleware of middlewares)
      middleware(query, params, options);

    return {
      url: pathname + stringifyQuery(query),
      to: {
        ...routeData,
        params,
        options: routeOptions,
      },
    };
  }, [pathname, routeData, middlewares]);
};
