import type { BillToCustomerListType } from './types';

//TICKET 124425 3.1 Branch visibility at the sub-account creation
export const SUB_ACCOUNT_BILLTOCUSTOMERS_REQUEST = 'SUB_ACCOUNT_BILLTOCUSTOMERS_REQUEST' as const;
export const requestBillToCustomers = () => ({
    type: SUB_ACCOUNT_BILLTOCUSTOMERS_REQUEST,
});

export const SUB_ACCOUNT_BILLTOCUSTOMERS_RECEIVED = 'SUB_ACCOUNT_BILLTOCUSTOMERS_RECEIVED' as const;
export const receivedBillToCustomers = (result: BillToCustomerListType) => ({
    type: SUB_ACCOUNT_BILLTOCUSTOMERS_RECEIVED,
    payload: result,
});

export type BillToCustomerAction = ReturnType<
    | typeof requestBillToCustomers
    | typeof receivedBillToCustomers
>;