//TICKET 124573 - 3.5. Create Account managers
import styles from './SubAccount.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'components/primitives/table';
import { SanaButton } from 'components/primitives/buttons';
import { RichText, UseSanaTexts, SimpleText } from 'components/sanaText';
import { makeSimpleText } from 'utils/render';
import { useIsMobileViewport } from 'utils/hooks';
import { useDispatch } from 'react-redux';
import { removeAccountManager } from 'behavior/pages/accountManagers';
import { Link } from 'components/primitives/links';
import { useRoutes } from 'components/primitives/route';
import { routesBuilder } from 'routes';
import { editRoute, routeIdReplacement, newRoute } from './constants';
import useConfirmation from 'components/objects/confirmation';
import RemoveButton from './RemoveButton';
import EditLink from './EditLink';
import { StatusSuccessIcon, StatusFailedIcon } from 'components/primitives/icons';
import { useHandlerLockerInPreview } from 'components/objects/preview';
import LinkPaging from './LinkPaging';

const textKeys = [
  'ButtonText_Edit', 'ButtonAltText_Edit',
  'AccountManagerName', 'AccountManagerActive', 'AccountManagerEmailAddress',
];

const List = ({ list, totalAccountManagers }) => {
  const dispatch = useDispatch();
  const { showConfirmation, clearConfirmation, confirmationModal } = useConfirmation();
  const [editPath, newPath = ''] = useRoutes([editRoute, newRoute]);
  const isMobile = useIsMobileViewport();

  const heading = (
    <h2><SimpleText textKey="AccountManagers" /></h2>
  );

  const addAccountBtn = (
    <SanaButton
      textKey="NewAccountManager"
      className={`${styles.newSubBtn} ${btnStyles.btnSmall}`}
      component={Link}
      to={newRoute}
      url={newPath}
    />
  );

  useEffect(() => clearConfirmation, []);

  const remove = useHandlerLockerInPreview(account => {
    const deleteAccount = () => dispatch(removeAccountManager(account.id));
    showConfirmation(
      deleteAccount,
      'DeleteConfirmation',
      <RichText textKey={'AccountManager_DeleteMessage'} formatWith={[account.name]} />,
      { okText: 'ButtonText_Delete' },
      undefined,
      styles.confirmationPopup,
    );
  });

  if (!list || list.length === 0)
    return (
      <>
        {heading}
        {addAccountBtn}
        <p className="msg-block"><RichText textKey="AccountManagers_NoAccountManagers" /></p>
      </>
    );

  return (
    <>
      {heading}
      {addAccountBtn}
      <UseSanaTexts options={textKeys}>
        {([editText, editAltText,
          nameText, activeText, emailText,
        ]) => (
          <Table className={styles.subAccounts}>
            <thead>
              <tr>
                <th id="accName-header">{makeSimpleText(nameText)}</th>
                <th className={styles.status} id="active-header">{makeSimpleText(activeText)}</th>
                <th id="accName-header">{makeSimpleText(emailText)}</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list.map(account => {
                return (
                  <tr key={account.id}>
                    <td className={styles.accNameCell}>{account.name}</td>
                    {renderBooleanColumn(account.activated, styles.activeCell)}
                    <td className={styles.accNameCell}>{account.email}</td>
                    <td className={styles.actions}>
                      <EditLink
                        url={editPath ? editPath.replace(routeIdReplacement, account.id) : ''}
                        to={routesBuilder.forAccountManager.bind(null, account.id)}
                        title={editAltText}
                        linkText={editText}
                        showBtnText={isMobile}
                      />
                      <RemoveButton onClick={remove.bind(this, account)} showBtnText={isMobile} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        )}
      </UseSanaTexts>
      <LinkPaging totalCount={totalAccountManagers} />
      {confirmationModal}
    </>
  );
};

List.propTypes = {
  list: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    activated: PropTypes.bool,
    email: PropTypes.string.isRequired,
  })),
};

export default List;

/* eslint-disable react/no-multi-comp */
function renderBooleanColumn(bool, className = '') {
  return (
    <td className={`${styles.status} ${className}`}>
      {bool
        ? <StatusSuccessIcon className={`${styles.bool} ${styles.ok}`} />
        : <StatusFailedIcon className={`${styles.bool} ${styles.failed}`} />
      }
    </td>
  );
}
