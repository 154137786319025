export const initialState = {
  modifiedDate: null,
  lastModifiedLineId: null,
  updated: {
    updaterId: null,
    date: null,
    linesAmount: 0,
  },
  model: null,
  isQuickOrderMode: false,
  summary: null,
  isErrorMode: false,
  accountInformationRefreshIconIsDisable: true, //TICKET 140916 - Account Information Visibility
};
