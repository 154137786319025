import {
  SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED,
  SubAccountAction,
} from './actions';
import { SubAccountRemovalResultType } from './constants';
import type { RemovalResultType, SubAccountState } from './types';

export default (state: SubAccountState = {}, action: SubAccountAction) => {
  if (action.type === SUB_ACCOUNT_REMOVAL_RESULT_RECEIVED)
    return onSubAccountsRemovalResultReceived(state, action);

  return state;
};

function onSubAccountsRemovalResultReceived(state: SubAccountState, { payload }: { payload: RemovalResultType }) {
  switch (payload.resultType) {
    case SubAccountRemovalResultType.Success:
      if (!state.subAccounts)
        return state;

      return {
        ...state,
        subAccounts: {
          list: state.subAccounts.list.filter(a => a.id !== payload.subAccountId),
        },
      };
    case SubAccountRemovalResultType.HasDependentAccounts:
      return {
        ...state,
        removalFailureInfo: {
          dependentAccounts: payload.dependentAccounts,
        },
      };
    case SubAccountRemovalResultType.HasPendingAuthorizations:
      return {
        ...state,
        removalFailureInfo: {
          authorizationsCount: payload.authorizationsCount,
        },
      };
    default:
      return state;
  }
}