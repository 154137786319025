//TICKET 140916 - Account Information Visibility
import { useState, useEffect } from 'react';
import { InfoAlert } from 'components/primitives/alerts';
import { useSanaTexts } from 'components/sanaText';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeRichText } from 'utils/render';
import { useDispatch } from 'react-redux';
import { modifyBasketForAccountInformation, changeAccountInformationRefreshIcon } from 'behavior/basket';
import { useCurrencyInfo } from 'utils/hooks';
import { getCurrencyAndNumber } from 'utils/format';
import { EmptyPriceSymbol } from 'utils/product';
import { requestGetBalanceInfo } from 'behavior/user';
import { RouteName } from 'routes';
import { usePrintMode } from 'utils/hooks';

const AccountInformationMessage = ({ messageId = 'accountInfoMsg', balanceInfo, updatedLinesAmount, basketModel, basketSummary, routeName, pageState, accountInformationRefreshIconIsDisable }) => {

  const dispatch = useDispatch();

  const currencyInfo = useCurrencyInfo();

  const [orderTotalIfInCheckoutPage, setOrderTotalIfInCheckoutPage] = useState(null);

  useEffect(() => {

    dispatch(requestGetBalanceInfo());

    if (routeName === 'Basket')
      dispatch(changeAccountInformationRefreshIcon(true));
    else if ((updatedLinesAmount && updatedLinesAmount === 1) ||
      (basketModel === null && basketSummary.productLines && basketSummary.productLines.list.length > 0)) {
      dispatch(changeAccountInformationRefreshIcon(false));
    }

    if (routeName === 'Checkout') {
      setOrderTotalIfInCheckoutPage(pageState.info.totals.price);
    }

  }, [basketSummary, updatedLinesAmount, routeName]);

  const GetPrice = price => {
    if (price === 0)
      return '0.00';
    let symbol, value = EmptyPriceSymbol;
    if (price != null)
      [symbol, value] = getCurrencyAndNumber(price || 0, currencyInfo);
    return value;
  };

  const { texts: [accountInfoMsg, reCalMsg] }
    = useSanaTexts(['AccountInformationMessage', 'ReCalMsg']);

  const creditLimit = (
    <span>
      <strong>{GetPrice(getCreditLimit(balanceInfo))}</strong>
    </span>
  );

  const openBalance = (
    <span>
      <strong>{GetPrice(getOpenBalance(balanceInfo))}</strong>
    </span>
  );

  const currentOrderTotal = (
    <span>
      <strong>{GetPrice(getCurrentOrderTotal(basketModel, routeName, orderTotalIfInCheckoutPage))}</strong>
    </span>
  );

  const newBalance = (
    <span>
      <strong>
        {GetPrice(getAvailableCredit(basketModel, balanceInfo, routeName, orderTotalIfInCheckoutPage))}
      </strong>
    </span>
  );

  const submit = () => {
    dispatch(modifyBasketForAccountInformation());
    dispatch(changeAccountInformationRefreshIcon(true));
  };

  const recalcBtn = (
    <>
      <button className="recalculate-link" onClick={() => submit()} disabled={accountInformationRefreshIconIsDisable}>
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="sync-alt" className="svg-inline--fa fa-sync-alt fa-w-16 " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M370.72 133.28C339.458 104.008 298.888 87.962 255.848 88c-77.458.068-144.328 53.178-162.791 126.85-1.344 5.363-6.122 9.15-11.651 9.15H24.103c-7.498 0-13.194-6.807-11.807-14.176C33.933 94.924 134.813 8 256 8c66.448 0 126.791 26.136 171.315 68.685L463.03 40.97C478.149 25.851 504 36.559 504 57.941V192c0 13.255-10.745 24-24 24H345.941c-21.382 0-32.09-25.851-16.971-40.971l41.75-41.749zM32 296h134.059c21.382 0 32.09 25.851 16.971 40.971l-41.75 41.75c31.262 29.273 71.835 45.319 114.876 45.28 77.418-.07 144.315-53.144 162.787-126.849 1.344-5.363 6.122-9.15 11.651-9.15h57.304c7.498 0 13.194 6.807 11.807 14.176C478.067 417.076 377.187 504 256 504c-66.448 0-126.791-26.136-171.315-68.685L48.97 471.03C33.851 486.149 8 475.441 8 454.059V320c0-13.255 10.745-24 24-24z"></path></svg>
      </button>
    </>
  );

  const isPrintMode = usePrintMode();
  if (isPrintMode)
    return null;

  return (
    <InfoAlert id={messageId}>
      {makeRichText(accountInfoMsg, [creditLimit, openBalance, currentOrderTotal, newBalance])}
      <span className="text-space">{routeName !== 'Basket' && makeRichText(reCalMsg, [recalcBtn])}</span>
    </InfoAlert>
  );

};

AccountInformationMessage.propTypes = {
  balanceInfo: PropTypes.object,
  basketModel: PropTypes.object,
  basketSummary: PropTypes.object,
  routeName: PropTypes.string,
  pageState: PropTypes.object,
  messageId: PropTypes.string,
  updatedLinesAmount: PropTypes.number,
  accountInformationRefreshIconIsDisable: PropTypes.bool,
};

const mapStateToProps = ({
  user,
  basket: { model, updated, summary, accountInformationRefreshIconIsDisable },
  routing: { routeData },
  page,
}) => ({
  balanceInfo: user.balanceInfo,
  updatedLinesAmount: updated.linesAmount,
  basketModel: model,
  basketSummary: summary,
  routeName: routeData ? routeData.routeName : RouteName.NotFound,
  pageState: page,
  accountInformationRefreshIconIsDisable,
});

export default connect(mapStateToProps)(AccountInformationMessage);

function getCreditLimit(balanceInfo) {
  if (balanceInfo && balanceInfo.creditLimit) {
    return balanceInfo.creditLimit;
  }
  return 0;
}

function getOpenBalance(balanceInfo) {
  if (balanceInfo && balanceInfo.balance) {
    return balanceInfo.balance;
  }
  return 0;
}

function getCurrentOrderTotal(basketModel, routeName, orderTotalIfInCheckoutPage) {
  if (routeName === 'Checkout') {
    return orderTotalIfInCheckoutPage;
  }

  if (basketModel && basketModel.totals) {
    return basketModel.totals.price;
  }
  return 0;
}

function getNewBalance(basketModel, balanceInfo, routeName, orderTotalIfInCheckoutPage) {
  if (routeName === 'Checkout') {
    if (balanceInfo && balanceInfo.balance) {
      return orderTotalIfInCheckoutPage + balanceInfo.balance;
    }
    else {
      return orderTotalIfInCheckoutPage + 0;
    }
  }

  if (basketModel && basketModel.totals) {
    if (balanceInfo && balanceInfo.balance) {
      return basketModel.totals.price + balanceInfo.balance;
    }
    else {
      return basketModel.totals.price + 0;
    }
  }
  if (balanceInfo && balanceInfo.balance) {
    return balanceInfo.balance;
  }
  return 0;
}

function getAvailableCredit(basketModel, balanceInfo, routeName, orderTotalIfInCheckoutPage) {
  const creditLimit = getCreditLimit(balanceInfo);
  const newBalance = getNewBalance(basketModel, balanceInfo, routeName, orderTotalIfInCheckoutPage);

  return creditLimit - newBalance;
}