//TICKET 124573 - 3.5. Create Account managers
import loadable from '@loadable/component';

const AccountManager = loadable(() => import(/*webpackChunkName:"accManage-acc"*/'./Details'));


/* eslint-disable react/no-multi-comp */
export function accountManagerRenderer(page) {
  return <AccountManager accountManager={page.accountManager} />;
}

