import loadable from '@loadable/component';

const Orders = loadable(() => import(/*webpackChunkName:"docs"*/'./Orders'));
const Quotes = loadable(() => import(/*webpackChunkName:"docs"*/'./Quotes'));
const Invoices = loadable(() => import(/*webpackChunkName:"docs"*/'./Invoices'));
const ReturnOrders = loadable(() => import(/*webpackChunkName:"docs"*/'./ReturnOrders'));
const CreditNotes = loadable(() => import(/*webpackChunkName:"docs"*/'./CreditNotes'));
const ReturnReceipts = loadable(() => import(/*webpackChunkName:"docs"*/'./ReturnReceipts'));
const Shipments = loadable(() => import(/*webpackChunkName:"docs"*/'./Shipments'));

function selectPropsFromPage({
  docs: {
    items,
    totalCount,
  },
  size,
  filter,
  billToCustomers,
}) {
  return {
    documents: items,
    totalCount,
    size,
    filter,
    billToCustomers,
  };
}

/* eslint-disable react/no-multi-comp */
export function ordersRenderer(page) {
  return <Orders {...selectPropsFromPage(page)} />;
}

export function quotesRenderer(page) {
  return <Quotes {...selectPropsFromPage(page)} />;
}

export function invoicesRenderer(page) {
  return <Invoices {...selectPropsFromPage(page)} />;
}

export function returnOrdersRenderer(page) {
  return <ReturnOrders {...selectPropsFromPage(page)} />;
}

export function creditNotesRenderer(page) {
  return <CreditNotes {...selectPropsFromPage(page)} />;
}

export function returnReceiptsRenderer(page) {
  return <ReturnReceipts {...selectPropsFromPage(page)} />;
}

export function shipmentsRenderer(page) {
  return <Shipments {...selectPropsFromPage(page)} />;
}
