//TICKET 124573 - 3.5. Create Account managers
import { memo } from 'react';
import PropTypes from 'prop-types';
import { formatText } from 'utils/formatting';
import { useSelector } from 'react-redux';
import { FormGroup, NewPasswordFormGroup } from 'components/objects/forms';
import { TextBoxField, EmailAddressField, CheckboxField, PasswordField } from 'components/objects/forms/fields';
import { useSimpleTexts } from 'components/sanaText';

const AccountManagerFields = ({ isEdit }) => {

  const [
    nameTitle,
    emailTitle,
    passwordTitle,
    confirmPasswordTitle,
    minPasswordPlaceholderTemplate,
    comparisonErrorMessage,
    accountIdTitle,
    activeTitle,
    canSeePricesTitle,
    canSeeStockTitle,
    canOrderTitle,
    PermissionsTX,
  ] = useSimpleTexts([
    'AccountManagerName',
    'AccountManagerEmailAddress',
    'Password',
    'ConfirmPassword',
    'CreateAccount_MinPasswordLength',
    'CreateAccount_DuplicatePasswordError',
    'AccountManagerAccountID',
    'AccountManagerActive',
    'AccountManagerMaySeePrices',
    'AccountManagerMaySeeStock',
    'AccountManagerMayOrder',
    'PermissionsTX',
  ]).texts;

  const minPasswordLength = useSelector(({ settings: { profile } }) => profile && profile.minPasswordLength);
  const newPasswordPlaceholder = formatText(minPasswordPlaceholderTemplate, [minPasswordLength]);


  return (
    <>
      <FormGroup
        fieldName="name"
        fieldTitle={nameTitle}
        fieldComponent={TextBoxField}
        maxLength={50}
        required
        validation={{
          required: true,
        }}
      />
      <FormGroup
        fieldName="email"
        fieldTitle={emailTitle}
        fieldComponent={EmailAddressField}
        maxLength={80}
        required
        validation={{
          required: true,
          uniqueEmail: true,
        }}
      />
      <NewPasswordFormGroup
        fieldName="password"
        fieldTitle={passwordTitle}
        placeholder={newPasswordPlaceholder}
      />
      <FormGroup
        fieldName="confirmPassword"
        fieldTitle={confirmPasswordTitle}
        fieldComponent={PasswordField}
        autoComplete="off"
        maxLength={30}
        required
        validation={{
          required: { allowWhitespace: true },
          equalsTo: { to: 'password', message: comparisonErrorMessage },
        }}
      />
      {!isEdit &&
        <FormGroup
          fieldName="referenceId"
          fieldTitle={accountIdTitle}
          fieldComponent={TextBoxField}
          maxLength={50}
          disabled
          required
          validation={{
            required: true,
          }}
        />
      }

      <FormGroup
        fieldName="activated"
        fieldTitle={activeTitle}
        fieldComponent={CheckboxField}
      />

      {!isEdit &&
        <>
        <div className="SanaForm_label"> {PermissionsTX}</div>
        <div className="reddit">
          <FormGroup
            fieldName="canSeePrices"
            fieldTitle={canSeePricesTitle}
            fieldComponent={CheckboxField}
            disabled
          />
          <FormGroup
            fieldName="canSeeStock"
            fieldTitle={canSeeStockTitle}
            fieldComponent={CheckboxField}
            disabled
          />
          <FormGroup
            fieldName="canOrderProducts"
            fieldTitle={canOrderTitle}
            fieldComponent={CheckboxField}
            disabled
          />
        </div>
        </>
      
      }
    </>
  );
};

AccountManagerFields.propTypes = {
  isEdit: PropTypes.bool.isRequired,

};
export default memo(AccountManagerFields);