import type { Api } from 'utils/api';
import type { Handler } from '../types';
import type { CustomerCollectionData, CustomerCollection } from './types';
import { representationPage } from './queries';
import { map } from 'rxjs/operators';
import { PageComponentNames } from '../componentNames';
import { initPageContent, SystemPage, SystemPageData } from '../system';
import { loadSystemPageQuery } from '../system/queries';
import { RouteName } from 'routes';

type RepresentRouteData = {
    routeName: RouteName.RepresentCompany;
    params?: {
        previewToken?: string;
    };
};

type RepresentSystemPageResponse = {
    pages: {
        representCompany: SystemPageData;
    };
};

type RepresentPageResponse = RepresentSystemPageResponse & {
    profile: {
        impersonationCompany: {
            customers: CustomerCollectionData;
        };
    };
};

type RepresentCompanyPage = SystemPage & {
    component: PageComponentNames.RepresentCompany;
    customers: CustomerCollection;
};

const handler: Handler<RepresentRouteData, RepresentCompanyPage> = ({ params }, _state$, { api }) => {
    if (params?.previewToken)
        return handlePreview(api);
    return api.graphApi<RepresentPageResponse>(representationPage).pipe(
        map(({ pages, profile: { impersonationCompany } }) => {
            const page = pages.representCompany;
            if (!impersonationCompany || !page)
                return null;

            const result = {
                page: initPageContent(page) as RepresentCompanyPage,
            };
            result.page.component = PageComponentNames.RepresentCompany;
            result.page.customers = impersonationCompany.customers;
            result.page.customers.keywords = '';

            return result;
        }),
    );
};

export default handler;

function handlePreview(api: Api) {
    return api.graphApi<RepresentSystemPageResponse>(loadSystemPageQuery('represent-company')).pipe(
        map(({ pages: { representCompany: page } }) => {
            if (!page)
                return null;
            const result = {
                page: initPageContent(page) as RepresentCompanyPage,
            };

            result.page.component = PageComponentNames.RepresentCompany;
            result.page.customers = {
                keywords: '',
                total: 5,
                list: Array.from(Array(5)).map((_, index) => ({
                    id: index.toString(),
                    name: '',
                    address: { postalAddress: '' },
                })),
            };

            return result;
        }),
    );
}
