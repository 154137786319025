//TICKET 124573 - 3.5. Create Account managers
import styles from '../SubAccount.module.scss';
import formStyles from 'components/objects/forms/SanaForm.module.scss';
import btnStyles from 'components/primitives/buttons/Button.module.scss';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SanaForm, useFormLockerOnSubmit } from 'components/objects/forms';
import { useDispatch } from 'react-redux';
import { submitAccountManager } from 'behavior/pages/accountManagers';
import { FormLockKeys } from 'behavior/pages';
import { SanaButton } from 'components/primitives/buttons';
import AccountManagerFields from './AccountManagerFields';
import { useAbilities } from 'components/objects/user';
import { AbilityState, AbilityTo } from 'behavior/user/constants';

const FORM_NAME = 'AccountManger';

const AccountManagerForm = ({ account, isEdit }) => {
  const dispatch = useDispatch();

  const referenceId = useSelector((state) => state.user.referenceId);

  const abilityKeys = [
    AbilityTo.ViewPrices,
    AbilityTo.OrderProducts,
    AbilityTo.ViewStock,
  ];
  const [ViewPricesAbility, OrderProductsAbility, ViewStockAbility] = useAbilities(abilityKeys).abilities;

  const onSubmit = useFormLockerOnSubmit(data => dispatch(submitAccountManager(data)), FormLockKeys.AccountManager, []);
  const initialValues = useMemo(() => {
    if (isEdit)
      return { ...account };
    return {
      referenceId: referenceId,
      canSeePrices: (ViewPricesAbility === AbilityState.Available) ? true : false,
      canOrderProducts: (OrderProductsAbility === AbilityState.Available) ? true : false,
      canSeeStock: (ViewStockAbility === AbilityState.Available) ? true : false,
    };
  }, [account]);

  return (
    <SanaForm
      key={account ? account.id : FORM_NAME}
      name={FORM_NAME}
      initialValues={initialValues}
      onSubmit={onSubmit}
      className={styles.form}
    >
      <AccountManagerFields isEdit={isEdit ? true : false} />
      <div className={`${formStyles.formRow} ${formStyles.rowActions}`}>
        <SanaButton
          textKey="Save"
          type="submit"
          className={`${btnStyles.btnBig} ${formStyles.btn} ${styles.btnSave}`}
        />
      </div>
    </SanaForm>
  );
};

AccountManagerForm.propTypes = {
  account: PropTypes.shape({
    active: PropTypes.bool,
    canOrderProducts: PropTypes.bool,
    canSeePrices: PropTypes.bool,
    canSeeStock: PropTypes.bool,
  }),
  isEdit: PropTypes.bool.isRequired,
};

export default AccountManagerForm;