//TICKET 124425 3.1 Branch visibility at the sub-account creation
import {
  BillToCustomerAction,
  SUB_ACCOUNT_BILLTOCUSTOMERS_REQUEST,
  receivedBillToCustomers,
} from './actions';
import { mergeMap, map } from 'rxjs/operators';
import { billToCustomersRequestQuery } from './queries';
import { EMPTY, merge } from 'rxjs';
import { handleError, ERROR_PRIORITIES } from 'utils/rxjs';
import { ofType } from 'redux-observable';
import { Epic } from 'behavior/types';

const billToCustomerEpic: Epic<BillToCustomerAction> = (action$, _state$, dependencies) => {
  const { api } = dependencies;

  const requestBillToCustomerSubAccount$ = action$.pipe(
    ofType(SUB_ACCOUNT_BILLTOCUSTOMERS_REQUEST),
    mergeMap(() => api.graphApi(billToCustomersRequestQuery, { retries: 0 }).pipe(
      map(data => receivedBillToCustomers({ ...data.profile.billToCustomers })),
      handleError(ERROR_PRIORITIES.MEDIUM, 'billToCustomerBranchers', () => EMPTY),
    )),
  );

  return merge(requestBillToCustomerSubAccount$);
};

export default billToCustomerEpic;