import styles from '../AccountMenu.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { useSelector, shallowEqual } from 'react-redux';
import LogoutButton from './LogoutButton';
import { routesBuilder } from 'routes';
import { ShopAccountRoleTypes } from 'behavior/user';
import { useCurrentRouteAsBackTo } from 'utils/hooks';

const CustomerMenuItems = ({ logoutDelay }) => {

  // TICKET 124413 - 3.4.Represent linked companies
  const { name, role, isLinkedMultipleAccounts} = useSelector(({ user }) => ({
    name: user.name,
    role: user.role,
    isLinkedMultipleAccounts: user.isLinkedMultipleAccounts,
  }), shallowEqual);

  const backTo = useCurrentRouteAsBackTo();

  return (
    <>
      <div className={styles.bodyHeader}>
        <span className="user-name"><SimpleText textKey="Login_Welcome" /> <b>{name}</b></span>
      </div>
      {(role === ShopAccountRoleTypes.AccountManager || role === ShopAccountRoleTypes.RegularAccount) && isLinkedMultipleAccounts &&
        (<Link to={routesBuilder.forRepresentCompany} options={{ backTo }} className={styles.item}>
        <SimpleText textKey="RepresentCompany" />
      </Link>)}
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

CustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default CustomerMenuItems;
