import styles from '../AccountMenu.module.scss';
import linkStyles from 'components/primitives/links/Link.module.scss';
import PropTypes from 'prop-types';
import { SimpleText } from 'components/sanaText';
import { WishlistLink, MyAccountLink, Link } from 'components/primitives/links';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import LogoutButton from './LogoutButton';
import { useCurrentRouteAsBackTo } from 'utils/hooks';
import { stopRepresentingCustomer } from 'behavior/user';
import { routesBuilder } from 'routes';
import { ShopAccountRoleTypes } from 'behavior/user';

const ImpersonatedCustomerMenuItems = ({ logoutDelay }) => {
  const dispatch = useDispatch();
  let represent;
  const { name, customerName, role, isLinkedMultipleAccounts } = useSelector(({ user }) => ({
    name: user.name,
    customerName: user.customer?.name,
    role: user.role,
    isLinkedMultipleAccounts: user.isLinkedMultipleAccounts,
  }), shallowEqual);

  const backTo = useCurrentRouteAsBackTo();

  // TICKET 124413 - 3.4.Represent linked companies
  if ((role === ShopAccountRoleTypes.AccountManager || role === ShopAccountRoleTypes.RegularAccount) && isLinkedMultipleAccounts) {
    represent = (<Link to={routesBuilder.forRepresentCompany} options={{ backTo }} className={styles.item}><SimpleText textKey="RepresentAnotherCompany" /></Link>);
  } else {
    represent = (<Link to={routesBuilder.forRepresent} options={{ backTo }} className={styles.item}><SimpleText textKey="RepresentAnotherCustomer" /></Link>);
  }

  return (
    <>
      <div className={styles.bodyHeader}>
        <div>
          <SimpleText textKey="Representing" /> <span className="customer-name"><b>{customerName}</b></span>
        </div>
        <div>
          <button className={`${linkStyles.link} ${linkStyles.arrowed} text-left`} onClick={() => dispatch(stopRepresentingCustomer())}>
            <SimpleText textKey="StopRepresentation" />
          </button>
        </div>
      </div>
      <div className={styles.bodyHeader}>
        <span className="user-name">
          <SimpleText textKey="LoggedInAs" /> <b>{name}</b>
        </span>
      </div>
      {represent}
      <MyAccountLink className={styles.item} />
      <WishlistLink className={styles.item} />
      <LogoutButton logoutDelay={logoutDelay} />
    </>
  );
};

ImpersonatedCustomerMenuItems.propTypes = {
  logoutDelay: PropTypes.number,
};

export default ImpersonatedCustomerMenuItems;
